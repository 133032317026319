import { useReducer } from "react";
import { v4 as uuidv4 } from 'uuid';

export const initialState = {
  cart: {},
};

function withoutProperty(obj, property) {
  const { [property]: unused, ...rest } = obj;

  return rest;
}

export const useCart = (initialState) => {
  const [cart, dispatch] = useReducer(reducer, initialState);
  return [cart, dispatch];
};

const reducer = (state, action) => {
  console.log('reducer was called',state,action);
  switch (action.type) {
    case "REMOVE_FROM_CART":
      return { ...state, cart: withoutProperty(state.cart, action.value.uuid) };

    case "UPDATE_ITEM_QUANTITY_IN_CART":
      if (action.value.amount === "0") {
        return { ...state, cart: withoutProperty(state.cart, action.value.uuid) };
      } else {
        return {
          ...state,
          cart: {
            ...state.cart,
            [action.value.uuid]: {
              ...state.cart[action.value.uuid],
              amount: action.value.amount,
            },
          },
        };
      }
    case "ADD_TO_CART":
      const id = uuidv4();
      if (action.value.amount !== "0") {
        return {
          ...state,
          cart: { ...state.cart, [id]: {...action.value,uuid:id }}
        };
      } else {
        return state;
      }
    case "CLEAR_CART":
      return {...state,
            cart: {}
          };
    case "INIT":
      return action.value;
    default:
      return state;
  }
};
