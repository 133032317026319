import React from "react";
import "./App.scss";
import { NavRouter } from "./components/router";

import { StateWrapper } from "./state/AppState";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>grrraves records - home of b0des, brxd sabbath, sixpoint</title>
        <meta name="description" content="The official grrraves records web store - home of b0des, brxd sabbath, sixpoint" />
        <meta name="author" content="grrraves" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <StateWrapper>
        <NavRouter />
      </StateWrapper>
    </>
  );
}
