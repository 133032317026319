import React, {Suspense} from 'react';
import { IGIcon, TwitterIcon,DiscordIcon,BandCampIcon } from "../utils/icons";

import Home3d from './Home3d';
const Home = () => {
    if(detectWebGL() === 1){
    return (
        <>
            <Suspense fallback={'...loading'}>
                <Home3d/>
            </Suspense>
        </>
    )}
    else{
        return (
        <div className="no-webgl">
            <p>
                Enable WebGL to Enter this <a href="https://get.webgl.org/" target="_blank"  rel="noreferrer" >Experience.</a>
            </p>
            <p className="cursor" >
            But if you can't check out our other cool things..
            <a href="https://www.instagram.com/grrraves.records" target="blank">
              <IGIcon/>
            </a>
            <a href="https://www.twitter.com/bodes_knows" target="blank">
              <TwitterIcon/>
            </a>
            <a href="https://dsc.gg/b0des" target="blank">
              <DiscordIcon/>
            </a>
            <a href="https://b0des.bandcamp.com/" target="blank">
              <BandCampIcon/>
            </a>
            </p>
        </div>)
    }
}


function detectWebGL()
{
    console.log("Check for webgl")
    // Check for the WebGL rendering context
    if ( !! window.WebGLRenderingContext) {
        var canvas = document.createElement("canvas"),
            names = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"],
            context = false;

        for (var i in names) {
            try {
                context = canvas.getContext(names[i]);
                if (context && typeof context.getParameter === "function") {
                    // WebGL is enabled.
                    return 1;
                }
            } catch (e) {}
        }

        // WebGL is supported, but disabled.
        return 0;
    }

    // WebGL not supported.
    return -1;
};
export {Home};