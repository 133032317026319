
import React , {useState} from "react";
import { useStateContext } from "../state/AppState";


export const Dropdown = ({product,selectFunction}) => {
    // eslint-disable-next-line
    const [state,setState] = useStateContext();
    const [active,setActive] = useState(false);
    
    const toggleActive = () => {
        setActive(!active)
    }
    
    const quantityMap = [1,2,3,4,5,6,7,8,9,10];

    return (
        <>
            <div className={active ? "dropdown is-active":"dropdown"} onClick={toggleActive}>
                <div className="dropdown-trigger">
                    <button
                    className="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    >
                    <span>Quantity - {product.amount}</span>
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    { quantityMap.map((quantity) =>{
                        return <div className="dropdown-content" key={quantity}>
                                <div className="dropdown-item" onClick={() => selectFunction(quantity)}>{quantity}</div>
                            </div>
                    })}
                </div>
            </div>
            <div>&nbsp;</div>
        </>
    )
};