import { createContext, useContext, useEffect, useMemo } from "react";
import {useCart,initialState} from '../hooks/useCart'

const StateContext = createContext();
export function StateWrapper({ children }) {
   const [state, dispatch] = useCart(initialState);

   const contextValue = useMemo(() => {
      return [state, dispatch];
   }, [state, dispatch]);


   useEffect(() => {
       if(JSON.parse(localStorage.getItem("state"))){
           dispatch({
               type: 'INIT',
               value: JSON.parse(localStorage.getItem('state')),
           })
       }
// eslint-disable-next-line react-hooks/exhaustive-deps
   },[])

   useEffect(() => {
       if(state !== initialState){
           localStorage.setItem("state", JSON.stringify(state));
       }
   },[state])

   return (
   <StateContext.Provider value={contextValue}>
      {children}
   </StateContext.Provider>
   );
}
export function useStateContext() {
   return useContext(StateContext);
}