import React from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../state/AppState";
import { Dropdown } from "./Dropdown";

const ProductsInCart = ({ products }) => {
  return Object.keys(products).map((productid) => {
    const product = products[productid];
    return (
      <div className="cart-item" key={product.uuid}>
        <div className="columns is-vcentered">
        <div className="column">
          <img className="cart-image" src={product.img_url} alt={product.img_alt} />
        </div>

        <div className="description column">
          <h5 className="cart-title">{product.title}</h5>
          <h5 className="cart-price">${product.price / 100}</h5>
          {product.options && Object.keys(product.options).map(option=> {
            return (<p>{option}: {product.options[option]}</p>)
          })}

          </div>
          <div className="column">
            <div className="level level-left">
              <QuantityInput product={product} />
              <RemoveFromCart product={product} />
            </div>
          </div>
        </div>
        </div>
    );
  });
};
const Checkout = ({ products }) => {
  const postProducts = async (products) => {
    let line_items = JSON.stringify(
      Object.keys(products).map((key) => products[key])
    );
    console.log("line_items", line_items);
    let fetchURL = "https://api.grrraves.com/create-checkout-session/";
    if (process.env.REACT_APP_CHECKOUT_LOCAL) {
      fetchURL = "http://localhost:4242/create-checkout-session/";
    } else if (process.env.REACT_APP_CHECKOUT_DEV) {
      fetchURL =
        "https://api.grrraves.lateralcoders.com/create-checkout-session/";
    } 
    console.log(fetchURL);
    let response = await fetch(fetchURL, {
      //let response = await fetch(`https://api.grrraves.lateralcoders.com/create-checkout-session/`,{
      method: "POST",
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body: line_items,
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    let res_url = await response.json();
    window.location = res_url.url;
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        postProducts(products);
      }}
    >
      <input type="hidden" name="product" id="product" value={products} />
      <input className="button" type="submit" value="Checkout" />
    </form>
  );
};
const QuantityInput = ({ product }) => {
  // eslint-disable-next-line
  const [state,setState] = useStateContext();


  
  const selectFunction = (quantity) => {
    setState({
        type: "UPDATE_ITEM_QUANTITY_IN_CART",
        value: {
            ...product,
            amount: quantity,
        },
    });

} 

  return (
    <Dropdown product={product} selectFunction={selectFunction} mode="cart"></Dropdown>
  );
};

const RemoveFromCart = ({ product }) => {
  // eslint-disable-next-line
  const [state,setState] = useStateContext();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setState({ type: "REMOVE_FROM_CART", value: { uuid: product.uuid } });
      }}
    >
      <input type="hidden" name="product" id="product" value={product.id} />
      <input className="button" type="submit" value="Remove From Cart" />
    </form>
  );
};
export const Cart = () => {
  const [state] = useStateContext();

  return (
    <section className="cart">
      {Object.keys(state.cart).length !== 0 && <div className="cart-topline"></div>}
      <ProductsInCart products={state.cart} />
      { Object.keys(state.cart).length === 0 && <div className="cart-nothing">😥 there is nothing in the shopping cart. 😥 <br/> <Link to="/store">to the shop</Link></div>}
      { Object.keys(state.cart).length !== 0 && 
     <div className="cart-checkout">
        <div className="level">
          <div className="level-item level-right">
           <span className="cart-subtotal">subtotal: ${Object.keys(state.cart).length !== 0 && (Object.keys(state.cart).map((key) => (state.cart[key].price/100) * state.cart[key].amount)).reduce((previousValue, currentValue) => previousValue + currentValue).toFixed(2)}</span>
          </div>
          <div className="level-item level-right">
            <Checkout products={state.cart} />
          </div>
        </div>
      </div>
    }
    </section>
  );
};
//https://dugsw5bel1.execute-api.us-east-1.amazonaws.com/production/create-checkout-session/
