import React, {Suspense} from 'react';

const PaymentSucess = () => {
    
    return (
        <>
            <Suspense fallback={'...loading'}>
                <div className='payment-status'>
                    <h1>we have recived your payment and are processing your order! orders are hand-fulfilled by b0des himself so please be patient! <br/><br/>thanks for shopping with us!</h1>
                </div>
            </Suspense>
        </>
    )
}

export {PaymentSucess};