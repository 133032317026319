import React,{useEffect, useState }from 'react';

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { useStateContext } from '../state/AppState';



export const Nav = () => {

    const [mobile,setMobile]= useState(false);
    const toggleMobile = () => {
        console.log("MOBILE",mobile)
        setMobile(!mobile)
    }
    useEffect(() => console.log("Mounted state",mobile),[mobile])

    const [state] = useStateContext();
    
    const cartTotal = () => {
        console.log('state is',state);
        let quantity="";
        if (Object.keys(state.cart).length !== 0) {
            quantity = Object.keys(state.cart).map(
                (key) => state.cart[key].amount)
                .reduce((previous,current) => previous+current)
        }
        
        
        
        return (
            quantity>0 ? 
            <span>&nbsp;({quantity})</span> :
            <span></span> 

        )
    }
    return (
        <nav className="navbar is-transparentboi">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    grrraves{//<img src="https://bulma.io/images/bulma-logo.png" alt="Bulma: a modern CSS framework based on Flexbox" width="112" height="28"/>
                    }
                </Link>
                <div className={mobile ? "navbar-burger is-active":"navbar-burger"} onClick={toggleMobile}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div id="navbarExampleTransparentExample" className={ mobile ? "navbar-menu is-transparent is-active":"navbar-menu is-transparent"}>
                <div className="navbar-start">
                    <Link className="navbar-item" to="/store" onClick={() => setMobile(false)}>
                        shop
                    </Link>

                    {/* <Link className="navbar-item" to="/artists">
                        artists
                    </Link> */}
               
                </div>
                <div className="navbar-end">                    
                    <Link className="navbar-item" to="/cart" onClick={() => setMobile(false)}>                       
                        {mobile ? <FontAwesomeIcon icon={faShoppingCart}/> : "cart" }
                       {cartTotal()} 
                    </Link>
                </div>
            </div>
        </nav>

    )
}
