 const products = {        
     'resident-evil-hoodie': {
        'title': "b0des - HIGH QUALITY Resident Evil (hoodie)",
        'price': 8499,
        'description': "We had this hoodie pressed up in honor of the new b0des track \"RESIDENT EVIL\" featuring Brxd Sabbath and Mr Gates. The art for this was hand made by b0des using crystal skulls, mystic flowers, tarot cards, chess boards, CRT tvs and analog circuit benders. The front is the single art and the back is the \"grrraves\" logo. This hoodie is super high quality -  with the artwork printed via a DTG (Direct To Garment) printer - no screen printing on this one.  ",
        'img_url': process.env.PUBLIC_URL + '/images/_DSC1599.small.jpg',
        'img_alt': 'b0des - resident evil (hoodie)',
        'images': [
            {
                'key': '_DSC1604',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1599.small.jpg',
                'img_alt': 'b0des - resident evil (hoodie)'
            },
            {
                'key': '_DSC1604',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1595.small.jpg',
                'img_alt': 'b0des - resident evil (hoodie)'
            },
            {
                'key': '_DSC1604',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1597.small.jpg',
                'img_alt': 'b0des - resident evil (hoodie)'
            },
            {
                'key': '_DSC1611',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1609.small.jpg',
                'img_alt': 'b0des - resident evil (hoodie) (front)'
            },{
                'key': '_DSC1612',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1610.small.jpg',
                'img_alt': 'b0des - resident evil (hoodie) (back)'
            }
        ],
        'options':{
            'size':['medium','large','x-large','xx-large']
            }
     },  
     'resident-evil-shirt': {
        'title': "b0des - HIGH QUALITY Resident Evil (t-shirt)",
        'price': 5999,
        'description': "We had this T-shirt pressed up in honor of the new b0des track \"RESIDENT EVIL\" featuring Brxd Sabbath and Mr Gates. The art for this was hand made by b0des using crystal skulls, mystic flowers, tarot cards, chess boards, CRT tvs and analog circuit benders. The front is the single art and the back is the \"grrraves\" logo. This shirt is super high quality -  with the artwork printed via a DTG (Direct To Garment) printer - no screen printing on this one. ",
        'img_url': process.env.PUBLIC_URL + '/images/_DSC1604.small.jpg',
        'img_alt': 'b0des - resident evil (t-shirt)',
        'images': [
            {
                'key': '_DSC1604',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1604.small.jpg',
                'img_alt': 'b0des - resident evil (t-shirt)'
            },
            {
                'key': '_DSC1611',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1611.small.jpg',
                'img_alt': 'b0des - resident evil (t-shirt) (front)'
            },{
                'key': '_DSC1612',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1612.small.jpg',
                'img_alt': 'b0des - resident evil (t-shirt) (back)'
            },{
                'key': '_DSC1605',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1605.small.jpg',
                'img_alt': 'b0des - resident evil (t-shirt)'
            },{
                'key': '_DSC1607',
                'img_url': process.env.PUBLIC_URL + '/images/_DSC1607.small.jpg',
                'img_alt': 'b0des - resident evil (t-shirt)'
            }
        ],
        'options':{
            'size':['medium','large','x-large','xx-large']
            }
     },      
    'titrathe': {
        'title': "titrathe (CD)",
        'price': 1777,        
        'description': "Debut LP by b0des",
        'images': [
            {
                'key': 'titrathe_outside',
                'img_url': process.env.PUBLIC_URL + '/images/titrathe_outside.jpg' ,
                'img_alt': 'titrathe CD case front'
            },{
                'key': 'titrathe_inside',
                'img_url': process.env.PUBLIC_URL + '/images/titrathe_inside.jpg' ,
                'img_alt': 'titrathe CD case opened'
            },
            {
                'key': 'titrathe_inside2',
                'img_url': process.env.PUBLIC_URL + '/images/titrathe_inside2.jpg' ,
                'img_alt': 'titrathe CD case opened'
            },{
                'key': 'titrathe_back',
                'img_url': process.env.PUBLIC_URL + '/images/titrathe_back.jpg' ,
                'img_alt': 'titrathe CD case front'
            },{
                'key': 'titrath_img1',
                'img_url': "https://i1.sndcdn.com/artworks-000546137226-26s2l1-t500x500.jpg",
                'img_alt': 'Product Cover'
            }
        ],
        'img_url': "https://i1.sndcdn.com/artworks-000546137226-26s2l1-t500x500.jpg",
        'img_alt': 'Product Cover',
        'options':{
            // 'color':['red','blue','green'],
            // 'size':['small','medium','large']
            },
        },
    
    // 'kd9e3': {
    //     'title': "titrathe (CD) (Signed)",
    //     'price': 7777,
    //     'description': "Debut LP by b0des - Signed",
    //     'img_url': "https://i1.sndcdn.com/artworks-000546137226-26s2l1-t500x500.jpg",
    //     'img_alt': 'Product Cover'
    // },
    'stilldexd': {
        'title': "...still dexd till dexth... (CD)",
        'price': 1666,
        'description': "Debut LP by Brxd Sabbath",
        'img_url': "https://i1.sndcdn.com/artworks-000679687267-ni586d-t500x500.jpg",
        'img_alt': 'still dexd till dexth album cover',
        'images': [
            {
                'key': 'stilldexd_outside',
                'img_url': process.env.PUBLIC_URL + '/images/stilldexd_outside.jpg' ,
                'img_alt': 'stilldexd CD case front'
            },{
                'key': 'stilldexd_inside',
                'img_url': process.env.PUBLIC_URL + '/images/stilldexd_inside.jpg' ,
                'img_alt': 'stilldexd CD case opened'
            },
            {
                'key': 'stilldexd_inside2',
                'img_url': process.env.PUBLIC_URL + '/images/stilldexd_inside2.jpg' ,
                'img_alt': 'stilldexd CD case opened'
            },{
                'key': 'stilldexd_unfolded_front',
                'img_url': process.env.PUBLIC_URL + '/images/stilldexd_unfolded_front.jpg' ,
                'img_alt': 'stilldexd til dexth unfolded front'
            },{
                'key': 'stilldexd_inside_unfolded',
                'img_url': process.env.PUBLIC_URL + '/images/stilldexd_inside_unfolded.jpg' ,
                'img_alt': 'stilldexd til dexth inside unfolded'
            },{
                'key': 'stilldexd_back',
                'img_url': process.env.PUBLIC_URL + '/images/stilldexd_back.jpg' ,
                'img_alt': 'stilldexd CD case front'
            },{
                'key': 'stilldexd cover',
                'img_url': "https://i1.sndcdn.com/artworks-000679687267-ni586d-t500x500.jpg",
                'img_alt': 'still dexd till dexth album cover'
            },]
    },
    'void': {
        'title': "into the infernal void (CD)",
        'price': 1666,
        'description': "Second LP By Brxd Sabbath",
        'img_url': "https://i1.sndcdn.com/artworks-tb7Iy1zOwJvB1u29-tRLM4g-t500x500.jpg",
        'img_alt': 'into the infernal void main artwork',
        'images': [
            {
                'key': 'infernal_outside',
                'img_url': process.env.PUBLIC_URL + '/images/infernal_outside.jpg',
                'img_alt': 'into the infernal void CD case front'
            },{
                'key': 'infernal_inside',
                'img_url': process.env.PUBLIC_URL + '/images/infernal_inside.jpg',
                'img_alt': 'into the infernal void CD case opened'
            },{
                'key': 'infernal_inside2',
                'img_url': process.env.PUBLIC_URL + '/images/infernal_inside2.jpg',
                'img_alt': 'into the infernal void CD case opened'
            },{
                'key': 'infernal_unfolded_front',
                'img_url': process.env.PUBLIC_URL + '/images/infernal_unfolded_front.jpg',
                'img_alt': 'into the infernal void unfolded front'
            },{
                'key': 'infernal_inside_unfolded',
                'img_url': process.env.PUBLIC_URL + '/images/infernal_inside_unfolded.jpg',
                'img_alt': 'into the infernal void inside unfolded'
            },{
                'key': 'infernal_back',
                'img_url': process.env.PUBLIC_URL + '/images/infernal_back.jpg',
                'img_alt': 'into the infernal void CD case front'
            },{
                'key': 'infernal cover',
                'img_url': "https://i1.sndcdn.com/artworks-tb7Iy1zOwJvB1u29-tRLM4g-t500x500.jpg",
                'img_alt': 'into the infernal void main artwork'
            },]   
    },    
    'titrathe-shirt': {
        'title': "b0des - titrathe (t-shirt)",
        'price': 2500,
        'description': "b0des titrathe t-shirt",
        'img_url': 'https://grrraves.com/images/b0desTitratheShirtFrontV1.jpg',
        'img_alt': 'b0des titrathe t-shirt',
        'images': [
            {
                'key': 'b0desTitratheShirtFrontV1',
                'img_url': process.env.PUBLIC_URL + '/images/b0desTitratheShirtFrontV1.jpg',
                'img_alt': 'b0des titrathe t-shirt (front)'
            },{
                'key': 'b0desTitratheShirtBackV1',
                'img_url': process.env.PUBLIC_URL + '/images/b0desTitratheShirtBackV1.jpg',
                'img_alt': 'b0des titrathe t-shirt (back)'
            }
        ],
        'options':{
            'size':['medium','large','x-large','xx-large']
            }
     },
     'infernal-shirt': {
        'title': "brxd sabbath - infernal void (t-shirt)",
        'price': 2500,
        'description': "brxd sabbath - infernal void t-shirt",
        'img_url': 'https://grrraves.com/images/brxdInfernalShirtFrontV1.jpg',
        'img_alt': 'b0des titrathe t-shirt',
        'images': [
            {
                'key': 'brxdInfernalShirtFrontV1',
                'img_url': process.env.PUBLIC_URL + '/images/brxdInfernalShirtFrontV1.jpg',
                'img_alt': 'brxd sabbath - infernal void - t-shirt (front)'
            },{
                'key': 'brxdInfernalShirtFrontV2',
                'img_url': process.env.PUBLIC_URL + '/images/brxdInfernalShirtBackV1.jpg',
                'img_alt': 'brxd sabbath - infernal void - t-shirt (back)'
            }
        ],
        'options':{
            'size':['medium','large','x-large','xx-large']
            }
     },
     'grrraves-ultra-pack-signed': {
        'title': "grrraves records ultimate package (signed)",
        'price': 11777,
        'description': 'grrraves records ultra pack featuring both brxd sabbath LPs --- "into the infernal void" & "still dexd till dexth" as well as the b0des LP --- "titrathe". we also give you both shirts and we hook you up with a ton of stickers too.',
        'img_url': 'https://grrraves.com/images/grrravesUltraPackageV1signed.jpg',
        'img_alt': 'grrraves records ultra pack featuring both brxd sabbath LPs --- "into the infernal void" & "still dexd till dexth" as well as the b0des LP --- "titrathe". we also give you both shirts and we hook you up with a ton of stickers too.',
        'images': [
            {
                'key': 'grrravesUltraPackageV1signed',
                'img_url': process.env.PUBLIC_URL + '/images/grrravesUltraPackageV1signed.jpg',
                'img_alt': 'grrraves records ultra pack featuring both brxd sabbath LPs --- "into the infernal void" & "still dexd till dexth" as well as the b0des LP --- "titrathe". we also give you both shirts and we hook you up with a ton of stickers too.'
            }
        ],
        'options':{
            'b0des shirt size':['medium','large','x-large','xx-large'],
            'brxd shirt size':['medium','large','x-large','xx-large']
            }
     }     
}


const getProduct = (id) => {
    return products[id];
}

module.exports = {products,getProduct};