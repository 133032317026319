/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: DEGUIDER (https://sketchfab.com/DEGUIDER)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/realistic-male-head-6a480c4603cd4768b615393e93dbd7d0
title: Realistic Male Head
*/

import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'


export default function HumanHead({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/realistic_male_head/scene.gltf')
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    group.current.rotation.y += 0.001 
    //group.current.rotation.x += 0.0001
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 1, 0, 0]}>
        <group rotation={[Math.PI / 1, 0, 0]}>
          <group position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={3}>
            <mesh position={[0,1,0]}geometry={nodes.Object_4.geometry}  onPointerOver={(e) => setHover(true)} onPointerOut={(e) => setHover(false)}>
              {hovered ? <meshStandardMaterial wireframe color={'indigo'} />: <meshNormalMaterial materials={materials}/>}
            </mesh>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/realistic_male_head/scene.gltf')
