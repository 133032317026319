import React from "react";
import { Link } from "react-router-dom";
import { products} from '../utils/products'

const ProductDisplay = () => {
    return Object.keys(products).map(productKey => { 
       const product = products[productKey];
       console.log(product);
       return (  
        <Link to={"/product/"+productKey} key={productKey}>
          <div className="product">
            <img src={product.img_url} alt={product.img_alt}/>
            <div className="description">
              <h3>{product.title}</h3>
              <h5>${(product.price % 100 === 0) ? product.price / 100 + ".00" : product.price / 100 }</h5>            
            </div>
          </div>
        </Link>
      )});
  };
  //https://dugsw5bel1.execute-api.us-east-1.amazonaws.com/production/create-checkout-session/

  export const Store = () => {
  
    return (
      <div style={{width: '100vw',height: '90vh'}}>
        <div className="product-grid">
          <ProductDisplay />
        </div>
      </div>
    );
  }
  