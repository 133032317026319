import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { products } from '../utils/products';
import { useStateContext } from "../state/AppState";
import { Dropdown } from "../components/Dropdown";



const ProductImages = ({product}) => {
  const [currentImage,setCurrentImage] = useState(product.images[0]);
  return (
  <div className="column product-image-gallery">
    <div className="product-image-aspect">
      <img className="product-image "src={currentImage.img_url} alt={currentImage.img_alt}/>
     </div> 
    <div className="product-images-container">
      {product.images.map(image => (
        <img key={image.key} className={image.key === currentImage.key ? "product-images active" :"product-images"} src={image.img_url} alt={image.img_alt} onClick={() => setCurrentImage(image)}/>
      ))}
    </div>
  </div>
  );
}

const ProductOptions = ({product,setOption,currentOptions}) => {
  return (
    <div className="product-options">
      {Object.keys(product.options).map(optionGroup => {
        return (
        <div className="product-option-container" key={optionGroup}>
          <p className="product-option-title">{optionGroup.toLowerCase()}</p>
          {product.options[optionGroup].map(option => {
          return (<button className={currentOptions[optionGroup] === option ? "product-option active" : "product-option"} key={option} onClick={() => setOption(optionGroup,option)}>
                  {option}
                </button>)}
                )}
            </div>
            )
      })}
    </div>
  );
}

export const ProductPage = () => {

    let {id} = useParams();
    let navigate = useNavigate();
    // eslint-disable-next-line
    const [amount, setAmount] = useState(0);
    const product = products[id];
    const [currentOptions,setCurrentOptions] = useState({});
    // product.amount=3;

    if (!product.amount){
      product.amount=1;
    }
    // eslint-disable-next-line
    const [state, setState] = useStateContext();

      
  const selectFunction = (quantity) => {
    product.amount=quantity;
  }
  const addToCart = (e) => {
    if(product.options){
    if(Object.keys(currentOptions).length !== Object.keys(product.options).length){
      alert("Please select all options to add this product to cart!")
    }else{
        setState({type:"ADD_TO_CART",value:{
       ...product,
       id:id,
       amount:product.amount,
       options: currentOptions
       
      }});
      navigate('/cart');

/// window.location.pathname = "/cart";
    }
   }else{
    setState({type:"ADD_TO_CART",value:{
      ...product,
      id:id,
      amount:product.amount,
      options: {}
      
     }});
     navigate('/cart');
   }
  }

  const setOption = (optionGroup,option) => {
    setCurrentOptions({...currentOptions, [optionGroup]:option})
  }

       return (  
       <section className="product-page">
         <div className="product-breadcrum">/
          <span className="underline"><Link to="/store">products</Link></span>/
          <span className="underline">{id}</span>
         </div>
        <div className="columns center">
          {product.images ? 
          <ProductImages product={product}/> :
          (
            <div className="column">
                  <img className="product-image "src={product.img_url} alt={product.img_alt}/>
            </div>
            )
          }
          
          <div className="product-info column">
            <div className="product-content">
              <h3 className="product-title">{product.title}</h3>
              <p className="product-description">{product.description}</p>
              <h5 className="product-price">${product.price / 100}</h5>
            </div>
              {product.options &&
                <ProductOptions product={product} setOption={setOption} currentOptions={currentOptions}/>
              }
            <div className="level level-left">
            <Dropdown className="level-left"product={product} selectFunction = {selectFunction}/>
            <button className="button" onClick={() => addToCart()}>Add to Cart</button>
        </div>
          </div>
        </div>

      </section>
      )};
  //https://dugsw5bel1.execute-api.us-east-1.amazonaws.com/production/create-checkout-session/
