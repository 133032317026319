import React, { useEffect } from 'react';

import {
    BrowserRouter,
    Routes,
    Route,
    useNavigate,
  } from "react-router-dom";
import {Nav} from './Nav'
import {Home} from './Home'
import {Store} from './Store';
import Artists from './Artists3d';
import {Cart} from './Cart';
import {ProductPage} from './Product';
import { useStateContext } from '../state/AppState';
import { PaymentSucess } from './PaymentSucess';

const Success = () => {
      // eslint-disable-next-line
      const [state,setState] = useStateContext()
      let navigate = useNavigate();
      useEffect(() => {
          let params = new URLSearchParams(window.location.search);
  
          if(params.get("success")){
  
              console.log("Clearing cart..")
              // setState({type: 'INIT',value: {}});
              navigate("/paymentSuccess")
          } 
          // eslint-disable-next-line
      },[setState])
      return (<></>);
}
const NavRouter = () => {
    return (
    
    <BrowserRouter>
    <Success/>
    <Nav/>
     <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/artists" element={<Artists/>}/>
        <Route path="/store" element={<Store/>}/>
        <Route path="/cart" element={<Cart/>}/>
        <Route path="/product/:id" element={<ProductPage/>}/>
        <Route path="/paymentSuccess" element={<PaymentSucess/>}/>
      </Routes>
    </BrowserRouter>
    
    );
}


export {NavRouter};