import React, { useRef, Suspense } from "react";
import {
  Canvas,
  useFrame,
} from "@react-three/fiber";
import { useIntersect } from "@react-three/drei";
import { Scroll,ScrollControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";


import * as THREE from 'three'

//import { useSpring, animated } from '@react-spring/three'



const Ball = (props) => {
  const visible = useRef(false)
  const ref = useIntersect(isVisible => (visible.current = isVisible))
  const { height } = useThree((state) => state.viewport) 

  useFrame((state, delta) => {
     ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? 0 : -height / 2 + 1, 4, delta)
  })
  return (
    <group {...props}>
      <mesh ref={ref}>
        <sphereBufferGeometry />
        <meshStandardMaterial color={props.color} />
      </mesh>
    </group>
  )
}
 
const PositionControler = (props) => {
  const { width: w, height: h } = useThree((state) => state.viewport)
  // The way this works is we multiply by the negative of the viewport hight -h is in the center of the 2nd page -2h is in the center of the third page and so on.
  return (
    <Scroll>
      <Ball position={[0,-h*2,1]} color="hotpink"/>
      <Ball position={[0,0,0]} color="white"/>
      <Ball position={[w/2,-h*2,-3]} color="white"/>
      <Ball position={[0,-h,0]} color="yellow"/>
    </Scroll>
  )
}

 const Artists = () => {
  return (
    <Canvas>
      <Suspense fallback={null}>
      <ScrollControls
        pages={3} 
      >
        <PositionControler/>
      </ScrollControls>
      <pointLight position={[10, 10, ]} />
      <pointLight position={[-10, -10, 0]} color={'#fff893'} />
      <ambientLight intensity={0.4} />
      </Suspense>
    </Canvas>
  )
}

export default Artists;