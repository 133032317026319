import React, { useRef, useState, Suspense } from "react";
import {
  Canvas,
  useFrame,
} from "@react-three/fiber";
import { 
  Html,
  MeshDistortMaterial,
  Sphere
 } from "@react-three/drei";

//import { useSpring, animated } from '@react-spring/three'

import HumanHead from "./head";
//import Brainage from "./Brainage"
import { IGIcon, TwitterIcon,DiscordIcon,BandCampIcon } from "../utils/icons";

/*function Box(props) {
  // This reference will give us direct access to the mesh
  const ref = useRef();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    ref.current.rotation.x = ref.current.rotation.y += 0.01;
  });
  return (
    <mesh
      {...props}
      ref={ref}
      scale={active ? 1.5 : 1}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <octahedronGeometry />
      <meshStandardMaterial wireframe color={hovered ? "hotpink" : "orange"} />
    </mesh>
  );
}

*/
function Orb(props) {
  // This reference will give us direct access to the mesh
  const ref = useRef();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    ref.current.rotation.x = ref.current.rotation.y += 0.01;
  });
  return (
    <mesh
      {...props}
      ref={ref}
      scale={active ? 1.5 : 1}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <Sphere args={[5,1,1]}>
        <MeshDistortMaterial
          attach="material"
          distort={1} // Strength, 0 disables the effect (default=1)
          speed={1} // Speed (default=1)
          color={hovered ? "hotpink" : "yellow"}
          wireframe
        />
      </Sphere>
    </mesh>
  );
}
/*
function VideoTile(props) {
  const size = useAspect(4, 3, 0.005);

  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = process.env.PUBLIC_URL + props.url;
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    return vid;
  });

  //const [playVideo, setPlayVideo] = useState(false);
  const playVid = () => {
    video.play();
  };
  // Keep in mind videos can only play once the user has interacted with the site ...

  return (
    <mesh {...props} scale={size} position={props.position} onClick={playVid}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial color={"white"}>
        <videoTexture attach="map" args={[video]} />
      </meshBasicMaterial>
    </mesh>
  );
}

function VideoBackgrounds() {
  const { width: w, height: h } = useThree((state) => state.viewport);
  return (
    <Scroll>
      <VideoTile url="/10.mp4" position={[-w / 5, h * 0.2, -5]} />
      <VideoTile url="/10.mp4" position={[-w / 5, -h * 1, -5]} />
      <VideoTile url="/10.mp4" position={[w / 6, -h * 0.4, -5]} />
      <VideoTile url="/10.mp4" position={[w / 6, -h * 1.5, -5]} />
      <VideoTile url="/10.mp4" position={[-w / 5, -h * 2.2, -5]} />
    </Scroll>
  );
}

function Plane({ color = "white", materialProps, ...props }) {
  return (
    <mesh {...props}>
      <planeGeometry />
      <meshBasicMaterial {...materialProps} color={color} />
    </mesh>
  );
}

function Content({ left, children }) {
  const { contentMaxWidth, canvasWidth, margin } = useBlock();
  const aspect = 1.75;
  const alignRight = (canvasWidth - contentMaxWidth - margin) / 2;

  //alignRight * (left ? -1 : 1)
  return (
    <group
      position={[alignRight * (left ? -1 : 1), 0.5, 0]}
      scale={[
        (contentMaxWidth / aspect) * 0.1,
        (contentMaxWidth / aspect) * 0.1,
        0.2,
      ]}
    >
      {
        // <Plane materialProps={materialProps} scale={[contentMaxWidth, contentMaxWidth / aspect, 1]}  color={color} />
      }
      {children}
    </group>
  );
}
function ScaleToFit({ children, ...props }) {
  return (
    <group {...props} scale={1}>
      {children}
    </group>
  );
}
*/

export default function Home3d() {
  //const scrollArea = useRef()
  ///const onScroll = e => (state.top.current = e.target.scrollTop)
  // useEffect(() => void onScroll({ target: scrollArea.current }), [])

  return (
    <>
      <Canvas linear camera={{ zoom: 50, position: [0, 0, 500] }}>
        <ambientLight intensity={1} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
          {/* 
      <Block factor={1.5} offset={0}>
          <Content left />
        </Block>
      <Text
        anchorX="center" // default
        anchorY="middle" // default
        color={'#00000'}
        fontSize={.5}
        maxWidth={6}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'left'}
        position={[1.5,1.5,0]}
        >
      Next Generation Record Company concerned with Artist freedom.
    </Text>
      */}
              {/* <Text
                anchorX="center" // default
                anchorY="middle" // default
                color={"#00000"}
                fontSize={2}
                maxWidth={10}
                lineHeight={1}
                letterSpacing={0.02}
                textAlign={"left"}
                position={[0, 0, 0]}
              >
                implicit ends...
              </Text> */}
              <Suspense fallback={null}>
                <HumanHead position={[0, 2, 2]} /> 
                {/* <Brainage position={[0,0,0]}> */}
                {/* </Brainage> */}
              </Suspense>
              <Orb/>
          {/* Second section  IPHONE? VAX / Glitch between*/}
          <Html position={[-2, -2, 0]}>
            <a href="https://www.instagram.com/grrraves.records" target="blank">
              <IGIcon/>
            </a>


          </Html>
          <Html position={[-1, -2, 0]}>
            <a href="https://www.twitter.com/bodes_knows" target="blank">
              <TwitterIcon/>
            </a>


          </Html>
          <Html position={[0, -2, 0]}>
            <a href="https://dsc.gg/b0des" target="blank">
              <DiscordIcon/>
            </a>


          </Html>          
          <Html position={[1, -2, 0]}>
            <a href="https://b0des.bandcamp.com/" target="blank">
              <BandCampIcon/>
            </a>


          </Html>          
        </Canvas>
    </>
  );
}
